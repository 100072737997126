import React from "react";
import Artykul from "../components/artykul";
import {  graphql, Link } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/narzedzia-do-pracy-zdalnej-dla-firm.jpg";
import Lightbox from "../components/lightBox";
import Columns from "../components/columns";
import Container from "../components/container";
import styles from "./index.module.css";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "raporty-analiza-aktywnosci.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "dashboard-managera-narzedzia-do-pracy-zdalnej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
     zdjecie3: file(relativePath: { eq: "narzedzia-do-pracy-zdalnej-zarzadzanie-sprzedaza.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(relativePath: { eq: "zarzadzanie-projetkami-w-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
     zdjecie5: file(relativePath: { eq: "karta-kontrahenta-w-systemie-dla-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie6: file(relativePath: { eq: "kalendarz-online-empireo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
      zdjecie7: file(relativePath: { eq: "programy-do-pracy-zdalnej-komuniaktor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const Pracazdalnaagencja = ({ data }) => {
  return (
    <Artykul
      title="Narzędzia do pracy zdalnej w firmie - 3 najważniejsze grupy aplikacji"
      articleImage={obrazekArtykulu}
      keywords={["narzędzia do pracy zdalnej"]}
      articleImageAlt="programy do pracy zdalnej"
      metaTitle="Narzędzia do pracy zdalnej w firmie - 3 najważniejsze grupy aplikacji"
      metaDescription="Narzędzia do pracy zdalnej dla firm. Trzy kluczowe grupy aplikacji. Wsparcie koordynacji działań sprzedażowych oraz realizacji zleceń i projektów. Wypróbuj za darmo."
    >
      <br />
      <h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        Jak zadbać o efektywność w modelu hybrydowym?
      </h2>
      <p>
      Praca zdalna w 2020/2021 roku stała się czymś oczywistym. Obecna sytuacja na świecie zmusiła wiele firm do takiego modelu. Czy jest on efektywny? To zależy od tego czy firma była w stanie szybko dostosować się do nowej normalności. Bez <strong>narzędzi do pracy zdalnej dla firm</strong>, raczej ciężko będzie odnaleźć się w obecnej sytuacji, ale także i później. Wiele osób zakłada, że model hybrydowy (łączenie pracy na miejscu i zdalnej) zostanie z nami na dłużej. 
      </p>
      <p
      style={{
        paddingTop: 20,
        paddingBottom: 20

      }}>Jeżeli tak się stanie, warto zastanowić się: 
<ol>
<li>W jaki sposób poprawić efektywność pracy zdalnej? </li>
<li>W jaki sposób kierownicy mogą monitorować aktywność pracowników i ich wyniki?  </li>
<li>Jak sprawniej koordynować współpracę różnych zespołów sprzedażowych i tych odpowiedzialnych za realizację zleceń? </li>
<li>Czy i jakie narzędzia do pracy zdalnej są nam rzeczywiście potrzebne?</li>
<li>Czy w bezpieczny sposób przechowujesz kluczowe dane swojej firmy?</li>
<li>Czy zastępowalność pracowników jest możliwa? </li>
</ol>
</p>
<p>
Wpisując w google hasło “<strong>narzędzia do pracy zdalnej</strong>” otrzymamy wiele artykułów, które prześcigają się w liczbie narzędzi, które firma może wykorzystać. Tylko kto i po co ma testować “<strong>30 narzędzi do pracy zdalnej</strong>” czy “<strong>90 programów do pracy  zdalnej</strong>”? 
</p>
<p>
<strong>Kluczowe obszary, które powinny wspierać narzędzia do pracy zdalnej to:</strong>
<ul>
<li>koordynacja pracy zespołu sprzedaży, </li>
<li>koordynacja pracy zespołów odpowiedzialnych za dostarczenie usług lub produktów, </li>
<li>bezpieczne przechowywanie uporządkowanych informacji o klientach i projektach/zleceniach, </li>
<li>wygodna komunikacja w firmie. </li>
</ul>
</p>
<h2 style= {{
          textAlign: "left",
          marginBottom:40,
          marginTop:40
          }}><strong>Pierwsza grupa: Programy do pracy zdalnej dla firm</strong></h2>

<p>
Jako właściciel firmy lub kierownik danego obszaru z pewnością zastanawiasz się jak długo potrwa obecna 
sytuacja na rynku i co z tego wyniknie. Do tego czasu zdążyliście już wypracować własny model zdalnego 
zarządzania firmą i współpracy między pracownikami. 
<strong> Jeżeli nie jesteście zadowoleni z osiągniętego efektu, 
nadal powinniście poszukiwać nowych rozwiązań, które mogą zwiększyć efektywność pracy zdalnej. </strong>

</p>
<p>
Programy wspierające koordynację pracy firm są dostępne na rynku od wielu lat. 
Większe firmy, które mają potrzebę także zarządzania magazynem lub kluczowy jest dla nich obszar kadr i płac, decydują się na systemy ERP. <strong>Oprócz tego, rozwijają te oprogramowania o komplementarne narzędzia typu CRM lub systemy wspierające koordynację obszaru sprzedaży i realizacji. </strong>

</p>
<p>
Firmy średniej wielkości i małe opierają się o narzędzia gotowe do użycia np. funkcjonalne systemy CRM czy rozwiązania branżowe.
</p>
<h2 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Jakie funkcje posiadają takie narzędzia do pracy zdalnej?</strong></h2>
  
<h3 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Dashboard kierownika</strong></h3>
<p>Dashboard w systemach do zarządzania firmą jest oknem widocznym od razu po zalogowaniu. W Empireo można samodzielnie go dostosować, a nawet stworzyć kilka i wielokrotnie zmieniać. Na pulpicie najczęściej umieszczane są kalendarze i listy zadań lub zdarzeń pracowników oraz kluczowe raporty dla firmy np. wartość sprzedaży.
</p>

     <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={[
          "Dashboard dla managera w programie do pracy zdalnej - Empireo",
        ]}
      /> 
      <p>Chcesz przedstawić wyniki na spotkaniu? Wystarczy, że pokażesz swój dashboard lub wyeksportujesz raport.</p>
     <h3 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Wygodne i szybkie monitorowanie aktywności pracowników</strong></h3>
<p>Gdy nie ma bezpośredniego kontaktu w zespole kluczowa jest komunikacja oraz wymiana informacjami o aktualnie realizowanych zadaniach. Jednak nie może ona zajmować całego dnia. </p>
<p><strong> Z pomocą przychodzą narzędzia dostępne w Empireo i są to:</strong>
<ul>
<li>kanban - do monitorowania przepływu zleceń/ projektów,</li>
<li>widok szans sprzedaży - do monitorowania aktualnej sytuacji w sprzedaży,</li>
<li>raporty prezentujące aktywności pracowników.</li>
</ul>
</p>
<p><strong>Wystarczy szybkie spojrzenie na kanbana lub na raport, aby być na bieżąco w sytuacji danego zespołu. </strong>
</p>
<p>
Szanse sprzedaży pozwalają w kilka sekund ustalić liczbę tematów na każdym z etapów, odpowiedzialnego za kontakt, prognozę, a powiązana z szansami historia i zdarzenia wyjaśnią wszelkie wątpliwości. Podobnie jest w przypadku realizacji zleceń.
</p>

<h3 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Zorganizowany proces sprzedaży - ta sama, sprawdzona ścieżka dla każdego handlowca</strong></h3> 

      <p>
      Widok szans sprzedaży umożliwia w proste uporządkownie obsługi szans sprzedaży. Wprowadza jeden standard pracy dla wszystkich handlowców, co umożliwia szybkie wdrożenie usprawnień do procesu. Oprócz widoku, który prezentuje listę szans na poszczególnych etapach, równie sprawnie można zweryfikować odpowiedzialnego za szansę i to, czy zaplanował on względem niej działania. 
</p>
<p>
W karcie szansy są już szczegółowe informacje na temat potencjalnej transakcji: ustalenia z klientem, powiązane oferty i inne kluczowe z punktu pozyskania klienta dane. 
</p>

        <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie3]}
        alts={[
          "Zarządzanie procesem sprzedaży w programie do pracy zdalnej",
        ]}
      /> 
      <h3 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Kanban - osobny dla każdego zespołu</strong></h3> 
 <p>
 Programy do pracy zdalnej muszą łączyć różne działy, dlatego oprócz zarządzania sprzedażą, Empireo wspiera także obszar zarządzania projektami lub mniejszymi zleceniami.
</p>
  <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie4]}
        alts={[
          "Narzędzia do pracy zdalnej - kaban - zarządzanie realizacją zleceń i projektów",
        ]}
      />
<p>
Możesz tworzyć wiele widoków kanban jeżeli pomoże Ci to zapanować nad realizacją. Dla pracownika taki widok to uporządkowana lista zadań do wykonania, dla kierownika narzędzie do szybkiego zweryfikowania postępów. 
 </p>

   <h3 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Automatyczne raporty dla kierowników</strong></h3>
     <p>Jako manager nie musisz poświęcać czasu na zbieranie informacji od wielu osób. System na podstawie zdarzeń rejestrowanych przez pracowników automatycznie generuje aktualne raporty oraz listy zdarzeń.
</p>
       <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={[
          "Narzędzia do pracy zdalnej - raporty dla kierowników w CRM",
        ]}
      />  
      <p>Jeżeli w systemie nie ma jakiegoś raportu możemy go przygotować na podstawie Twoich indywidualnych wymagań. </p>
     <p></p>
 <h3 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Baza wiedzy o klientach</strong></h3> 
      <p>
      Kompleksowe systemy do pracy zdalnej wspierają obszar zarządzania bazą wiedzy o klientach. Dane o kontrahentach i współpracy z nimi są uporządkowane, bezpieczne, przechowywane w jednym miejscu, a nie na komputerach pracowników.
</p>
  <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie5]}
        alts={[
          "Baza klientów w programach do pracy zdalnej - Empireo",
        ]}
      /> 
<p>
W karcie kontrahenta możesz zamieścić dowolne atrybuty opisujące go , czyli segmentować klientów. A grupy/segmenty klientów możesz eksportować np. do zewnętrznego systemu mailingowego (jeżeli z takiego chcesz korzystać). 

</p>
    <h3 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Współdzielone kalendarze, planowanie zadań innym</strong></h3> 
<p>
Współdzielone kalendarze to duże udogodnienie dla zespołów. W szybki sposób możemy wyświetlić na kalendarzu zadania wybranych osób i umówić spotkanie zespołu bez kontaktu z każdym i weryfikowania czy ma czas. Proste, prawda?
</p>

  <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie6]}
        alts={[
          "Kalendarze online - wspólne kalendarze dla pracowników - Empireo",
        ]}
      /> 
<p>Oczywiście możliwe jest korzystanie z zadań prywatnych, widocznych tylko dla osoby tworzącej te zadanie.</p>

 
<h3 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Przestrzeń na pliki firmowe</strong></h3> 
<p>
Gdy praca zdalna trwa już drugi rok należy zastanowić się czy pliki firmowe, praca naszego zespołu jest przechowywana w miejscu dostępnym dla uprawnionych osób. W sytuacji odejścia pracownika utrata danych nie będzie kolejnym zmartwieniem. Nad aktualnością plików przechowywanych w jednym miejscu również łatwiej zapanować. Kluczowe dokumenty dla firmy możesz przechowywać w systemie Empiero w repozytorium plików. 
</p>
<h3 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Wbudowany komunikator</strong></h3> 

<p>Komunikacja to podstawa, także w narzędziach do pracy zdalnej. Wbudowany w system do pracy zdalnej komunikator zachowuje wszystkie ustalenia w firmie, zawsze można do nich wrócić. Komunikaty można wiązać z różnymi obiektami np. szansą, zleceniem. Wiadomości nie można usunąć.
</p>

  <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie7]}
        alts={[
          "Komunikator online - program do pracy zdalnej",
        ]}
      /> 

<p>
Jeżeli <strong>zarządzasz agencją reklamową</strong> zachęcam Cię do  wypróbowania za darmo systemu Empireo, który posiada wszystkie wymienione powyżej funkcje.
</p>
<Container>
      <Columns>
        <div className="text-answers">
          <div className="text-block">
            <p>
             <h2
style={{
paddingTop: 20,
paddingBottom: 20

}}>System do pracy zdalnej dla agencji reklamowej</h2>
              <h2
                style={{
                  textAlign: "center",
                  paddingLeft: "10px",
                  fontSize: 18,
                }}
              >
                30 dni za darmo. Bez zobowiązań. Bez karty kredytowej.
              </h2>
            </p>
            <p>
              {" "}
              <center>
                <Link className={styles.special} to="/demo/">
                  WYPRÓBUJ TERAZ
                </Link>
              </center>
            </p>
          </div>
        </div>
      </Columns>
    </Container>
<h2
style={{
paddingTop: 20,
paddingBottom: 20

}}>System do pracy zdalnej dla firm różnych branż</h2>

<p><strong>Jeżeli Twoja firma jest z innej branży - skontaktuj się z nami, abyśmy mogli przekazać Ci system dopasowany do specyfiki Waszej działalności.</strong> Jesteśmy producentem systemów CRM, platform B2B i systemów do zarządzania usługami. W szybki sposób możemy dopasować system do potrzeb Twojej branży.

</p>
<h2 style= {{
          textAlign: "left",
          marginBottom:40,
          marginTop:40
          }}><strong>Druga grupa: Narzędzia do komunikacji w pracy zdalnej</strong></h2>
<p>Akapit wyżej wymieniony został wbudowany w system do zarządzania firmą komunikator, który bezpiecznie przechowuje wszystkie ustalenia. Natomiast jeżeli chcecie zachować komunikację wizualną wewnątrz firmy czy z klientami, to najlepiej skorzystać z dedykowanych do tego narzędzi.
</p>
<p>
Do najpopularniejszych narzędzi do komunikacji zdalnej naszym zdaniem zaliczyć można:
<ol>
<li>Skype</li>
<li>Microsoft Teams</li>
<li>Google Hangouts</li>
</ol>
</p>
<p>Nieważne które wybierzecie, warto, abyście korzystali z jednego narzędzia. Każde z nich jest świetne w komunikacji bezpośredniej. </p>
<p>Próbowanie łączenia na siłę wszystkich narzędzi w jedno, spali na panewce. <strong>Wybierz TOP 3 narzędzia do pracy zdalnej dla Twojej firmy</strong>, które wspierają różne, ale kluczowe obszary. 
</p>
<h2 style= {{
          textAlign: "left",
          marginBottom:40,
          marginTop:40
          }}><strong>Trzecia grupa: Narzędzia do wspólnego tworzenia treści w pracy zdalnej</strong></h2>
<p>
Systemy do zarządzania firmą czy dedykowane aplikacje do rozmów video nie zastąpią narzędzi do wspólnego tworzenia treści. Ostateczne, doszlifowane pliki firmowe możesz również przechowywać w systemie do zarządzania firmą np. Empireo. Natomiast pracę nad ich wspólną edycją warto zostawić w dedykowanej do tego aplikacji np. narzędziach Google. 
</p>
<p>Dysk Google umożliwia tworzenie, współdzielenie z innymi, wspólną edycję i przechowywanie:
<ol>
<li>treści,</li>
<li>prezentacji, </li>
<li>arkuszy kalkulacyjnych,</li>
<li>formularzy,</li>
<li>innych. </li>
</ol>
</p>

<p>Możecie też stworzyć foldery i przechowywać w nim pliki. Narzędzia są bezpłatne i dostępne po założeniu konta gmail. </p>

<h3 style= {{
          textAlign: "left",
          marginBottom:40,
          marginTop:40
          }}><strong>Podsumowując</strong></h3>
          <p>
Narzędzia lub programy do pracy zdalnej powinny oferować szeroki pakiet możliwości. Przechowywanie informacji o klientach, zarządzanie sprzedażą, realizacją zleceń, planowanie pracy możesz prowadzić w systemie Empireo. Do komunikacji video polecamy zastosować jeden z popularnym systemów przeznaczonych głównie do tego. 
</p>
<p>
Przy wyborze rozwiązania dla firmy warto kierować się elastycznością narzędzia, tak aby bez wielkich nakładów finansowych nadążało za biznesem. Istotne jest także to, czy producent oferuje coś więcej, inny moduł (np. platformę B2B do sprzedaży hurtowej, system do zarządzania zleceniami) lub przejście na rozwiązanie dedykowane bez zakłócania pracy Twojej firmy.
</p>
<p>

Jeżeli szukasz rozwiązania dla Twojej firmy - napisz do nas, umówimy się na niezobowiązującą zdalną rozmowę i postaramy się doradzić odpowiednie podejście.
</p>
 <Container>
      <Columns>
        <div className="text-answers">
          <div className="text-block">
            <p>
              <h2
                style={{
                  textAlign: "center",
                  paddingLeft: "10px",
                }}
              >
                Sprawdź już dziś jak możesz odmienić pracę swojej agencji
              </h2>
              <h2
                style={{
                  textAlign: "center",
                  paddingLeft: "10px",
                  fontSize: 18,
                }}
              >
                30 dni za darmo. Bez zobowiązań. Bez karty kredytowej.
              </h2>
            </p>
            <p>
              {" "}
              <center>
                <Link className={styles.special} to="/demo/">
                  WYPRÓBUJ TERAZ
                </Link>
              </center>
            </p>
          </div>
        </div>
      </Columns>
    </Container>
    </Artykul>
  );
};

export default Pracazdalnaagencja;
